import axios from 'axios';
import config from '../config/index';
import store from '../store';
import auth from '../auth';

const xhr = axios.create({
  baseURL: config.url,
});

xhr.interceptors.request.use(
  async config => {
    let token = await auth.getToken();
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Add a response interceptor
xhr.interceptors.response.use(
  response =>
    // Do something with response data
    response,
  async error => {
    if (error.response) {
      if (error.response.status == 401) {
        store.dispatch('session/setErrors', {
          info: 'Vous avez été déconnecté',
          message:'Vous allez être redirigé vers la page d\'authentification.',
        });
        auth.logout();
      } else {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let info;
        if (error.response.status === 409) {
          info = 'Attention !';
        }
        const message = await getErrorMessage(error.response.data);
        store.dispatch('session/setErrors', { info, message });
      }


    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      store.dispatch('session/setErrors', { message: 'Erreur : sans réponse du serveur' });
      // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      store.dispatch('session/setErrors', { message: 'Erreur : problème d\'initilisation de la requête' });
      // console.log(error.request);
    }
    // console.log(error.config);

    // Do something with response error
    return Promise.reject(error);
  }
);

const getErrorMessage = async (data) => {
  const htmlRegex = /<p><b>message<\/b>\s(.*?)<\/p>/;
  let message = data.error;
  if (data instanceof Blob && data.type === 'application/json') {
    message = JSON.parse(await data.text()).error;
  } else if (data instanceof Object) {
    message = data.error;
  } else if ( (typeof data === 'string' || data instanceof String) && data.match(htmlRegex)) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = data.match(htmlRegex)[1];
    message = textArea.value;
  }
  return message;
};

const token = '';
xhr.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default xhr;
export let validateStatus404 = (status) => { return (status >= 200 && status < 300) || status == 404; };